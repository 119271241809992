<template>
  <div
    class="login"
    style="
    "
  >
    <h1
      style="    font-size: 30px;
                    font-weight: 700;
                    text-align: center;
                    color: #fff;
                    margin-top:140px;
                    margin-bottom: 0px;
                    "
    >
      Deer WMS
    </h1>
    <div
      style="border-radius: 10px;
                margin: 5px 15px 5px 8px;
                padding: 0px 20px 10px 0px;
                width:60%;
                height:450px;
                margin:50px auto;
                background-color: white;"
    >
      <div class="leftFloat" style="width:40%">
        <div style="margin:60px 0px 0px 30px;font-size:25px;font-weight: 700;color:#5379f6">智慧仓库管理系统</div>
        <div style="margin:5px 0px 5px 60px;color:#6c6b06">智能算法业务引擎 仓库大脑</div>
        <img style="width:300px;margin-left:30px;margin-top:0px" src="../assets/images/scan.jpg" />
      </div>

      <div class="loginModal rightFloat ">
        <ul>
          <li>
            <span
              style="    font-size: 24px;
                            font-weight: 700;
                            text-align: center;
                            margin-bottom: 10px;
                            color: #55667b;"
            >
              登录
            </span>
          </li>
          <li>
            <el-input
              @keyup.enter.native="commit"
              placeholder="请输入账号"
              v-model="form.account"
              prefix-icon="iconfont icon-ef-zhanghao"
              clearable
            >
            </el-input>
          </li>
          <li>
            <el-input
              @keyup.enter.native="commit"
              placeholder="请输入密码"
              v-model="form.password"
              prefix-icon="iconfont icon-mima"
              show-password
              clearable
            ></el-input>
            <div class="clearFloat" style="margin-top:10px">
              <el-slider
                title="拖到右侧"
                class="leftFloat"
                v-model="sliderValue"
                :show-tooltip="false"
                :disabled="sliderValue === 100"
                style="width: 10%; margin-left: 5%"
              ></el-slider>
              <div
                class="leftFloat"
                style="
                                    font-size: 14px;
                                    line-height: 38px;
                                    margin-left: 10px;
                                "
              >
                <span v-show="sliderValue !== 100" style="color: #a9b0c6">滑动解锁</span>
                <span v-show="sliderValue === 100" style="color: #08274c">已解锁!</span>
              </div>
              <el-button class="rightFloat" type="text" @click="dialogNewVisible = true">注册账号</el-button>
              <el-button class="rightFloat" type="text" style="margin-right:5px" @click="dialogUpdateVisible = true">忘记密码</el-button>
            </div>
          </li>
          <li>
            <el-button
              @click="commit"
              type="primary"
              :disabled="form.account === '' || form.password === '' || sliderValue !== 100"
              style="width: 100%;height:50px;font-size:20px;background-color:#5379f6"
              >登陆</el-button
            >
          </li>
        </ul>
      </div>

      <el-dialog title="注册" :visible.sync="dialogNewVisible" width="500px" center>
        <el-form label-position="left" :model="newOrg" :rules="rules" ref="newOrg">
          <el-form-item label="姓名" prop="userName" :label-width="formLabelWidth">
            <el-input clearable v-model="newOrg.userName" placeholder="请输入姓名" maxlength="20" style="width: 60%;"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phoneNumber" :label-width="formLabelWidth">
            <el-input clearable v-model="newOrg.phoneNumber" placeholder="请输入手机号" maxlength="20" style="width: 60%;"></el-input>
            <el-button v-if="!timeBool" @click="phoneCode" style="width: 30%;">发送验证码</el-button>
            <el-button v-if="timeBool" style="width: 30%;">{{ timeCode }}s</el-button>
          </el-form-item>
          <el-form-item label="验证码" prop="code" :label-width="formLabelWidth">
            <el-input clearable v-model="newOrg.code" placeholder="请输入验证码" maxlength="20" style="width: 60%;"></el-input>
          </el-form-item>
          <input name="password" type="password" style="display:none" autocomplete="new-password" />
          <el-form-item label="密码" prop="password" :label-width="formLabelWidth">
            <el-input
              style="width: 60%;"
              placeholder="请输入密码"
              v-model="newOrg.password"
              prefix-icon="iconfont icon-mima"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password2" :label-width="formLabelWidth">
            <el-input
              style="width: 60%;"
              placeholder="请输入密码"
              v-model="newOrg.password2"
              prefix-icon="iconfont icon-mima"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogNewVisible = false">取 消</el-button>
          <el-button type="primary" @click="register()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="找回密码" :visible.sync="dialogUpdateVisible" width="35%" center>
        <el-form :model="updatePassword" :rules="rules2" ref="updatePassword">
          <el-form-item label="手机号" prop="mobile" :label-width="formLabelWidth">
            <el-input clearable v-model="updatePassword.mobile" placeholder="请输入手机号" maxlength="20" style="width: 60%;"></el-input>
            <el-button v-if="!timeBool" @click="phoneCode2" style="width: 30%;">发送验证码</el-button>
            <el-button v-if="timeBool" style="width: 30%;">{{ timeCode }}s</el-button>
          </el-form-item>
          <el-form-item label="验证码" prop="code" :label-width="formLabelWidth">
            <el-input clearable v-model="updatePassword.code" placeholder="请输入验证码" maxlength="20" style="width: 90%;"></el-input>
          </el-form-item>
          <input name="password" type="password" style="display:none" autocomplete="new-password" />
          <el-form-item label="新密码" prop="password" :label-width="formLabelWidth">
            <el-input
              style="width: 90%;"
              placeholder="请输入密码"
              v-model="updatePassword.password"
              prefix-icon="iconfont icon-mima"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password2" :label-width="formLabelWidth">
            <el-input
              style="width: 90%;"
              placeholder="请输入密码"
              v-model="updatePassword.password2"
              prefix-icon="iconfont icon-mima"
              show-password
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogUpdateVisible = false">取 消</el-button>
          <el-button type="primary" @click="updatePasswordCommit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
  function getQueryVariable(variable) {
    debugger;
    var url = decodeURI(decodeURI(location.search)); //解码  解决中文乱码问题
    var query = url.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return undefined;
  }
  function replaceParamVal(name, val, isRefresh) {
    var url = location.href.toString();
    //console.log(url);
    var pattern = '[\?]' + name + '=([^&]*)';
    var pattern2 = '[&]' + name + '=([^&]*)';
    var replaceText = name + '=' + val;
    var replaceText1 = '\?' + replaceText;
    var replaceText2 = '&' + replaceText;
    if (url.match(pattern)) {
      var tmp = '/\\?(' + name + '=)([^&]*)/gi';
      var nUrl = url.replace(eval(tmp), replaceText1);
    } else if (url.match(pattern2)) {
      var tmp = '/&(' + name + '=)([^&]*)/gi';
      var nUrl = url.replace(eval(tmp), replaceText2);
    } else {
      if (url.match('[\?]')) {
        var nUrl = url + '&' + replaceText;
      } else {
        var nUrl = url + '?' + replaceText;
      }
    }
    if (isRefresh) {
      window.location.href = nUrl;
    }
    var stateObject = { id: '' };
    var title = '';
    history.replaceState(stateObject, title, nUrl);
  }
  import Footer from '@/components/Footer.vue';

  export default {
    name: 'Login',
    data() {
      let validatePassword2 = (rule, value, callback) => {
        if (value != this.newOrg.password) {
          callback(new Error('两次密码不一致'));
        } else {
          callback();
        }
      };
      let validatePassword3 = (rule, value, callback) => {
        if (value != this.updatePassword.password) {
          callback(new Error('两次密码不一致'));
        } else {
          callback();
        }
      };
      return {
        prevRoute: null,
        updatePassword: {},
        dialogUpdateVisible: false,
        urlOrgId: '11',
        autoLogin: false,
        timer: null,
        timeCode: 60,
        timeBool: false,
        formLabelWidth: '80px',
        newOrg: {},
        updatePassword: {
          mobile: '',
          code: '',
          password: '',
          password2: '',
        },
        dialogNewVisible: false,
        form: {
          account: '',
          password: '',
          systemType: 1,
        },
        sliderValue: 100,
        rules: {
          organizationName: [{ required: true, message: '请输入公司名', trigger: 'blur' }],
          userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          // account: [
          //     {required: true, message: '请输入账号', trigger: 'blur'}
          // ],
          phoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
          password2: [
            { required: true, message: '请确认密码', trigger: 'blur' },
            { validator: validatePassword2, trigger: 'blur' },
          ],
        },
        rules2: {
          mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
          password2: [
            { required: true, message: '请确认密码', trigger: 'blur' },
            { validator: validatePassword3, trigger: 'blur' },
          ],
        },
      };
    },
    computed: {
      account() {
        return this.form.account;
      },
      password() {
        return this.form.password;
      },
    },
    watch: {
      sliderValue(val) {
        if (val !== 100) {
          this.sliderValue = 0;
        }
      },
      account(val) {
        this.sliderValue = 0;
      },
      password(val) {
        this.sliderValue = 0;
      },
    },
    components: {
      Footer,
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from;
      });
    },
    methods: {
      getQueryVariable(variable) {
        var query = window.location.hash.split('?')[1];
        if (query == null || query == '') {
          return;
        }
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (pair[0] == variable) {
            return pair[1];
          }
        }
        return false;
      },
      getAuthParams() {
        this.urlOrgId = this.getQueryVariable('orgId');
        this.newOrg.urlOrgId = this.urlOrgId;
      },
      register() {
        let that = this;

        this.$refs['newOrg'].validate((valid) => {
          if (valid) {
            //   IOT.showOverlay('保存中，请稍等...');
            let newOrg = that.newOrg;
            let url = '/users/register2';
            IOT.getServerData(url, 'post', that.newOrg, (ret) => {
              //  IOT.hideOverlay();
              if (ret.code === 200) {
                that.dialogNewVisible = false;
                IOT.tips('注册成功！', 'success', 1000, () => {
                  that.form.account = newOrg.phoneNumber;
                  that.form.password = newOrg.password;
                  that.sliderValue = 100;
                  that.commit();
                });
              } else {
                IOT.tips(ret.data || ret.message, 'error');
              }
            });
          } else {
            IOT.hideOverlay();
            console.error('error submit!!');
            return false;
          }
        });
      },
      updatePasswordCommit() {
        let that = this;

        this.$refs['updatePassword'].validate((valid) => {
          if (valid) {
            IOT.showOverlay('保存中，请稍等...');
            let updatePassword = that.updatePassword;
            let url = '/users/retrieval/password';
            IOT.getServerData(url, 'post', that.updatePassword, (ret) => {
              IOT.hideOverlay();
              if (ret.code === 200) {
                that.dialogUpdateVisible = false;
                IOT.tips('注册成功！', 'success', 1000, () => {
                  that.form.account = updatePassword.mobile;
                  that.form.password = updatePassword.password;
                  that.sliderValue = 100;
                  that.commit();
                });
              } else {
                IOT.tips(ret.data || ret.message, 'error');
              }
            });
          } else {
            return false;
          }
        });
      },
      phoneCode() {
        let that = this;
        if (that.newOrg.phoneNumber == undefined || that.newOrg.phoneNumber == null) {
          IOT.tips('请输入正确的11位手机号码', 'error');
          return;
        }
        var phoneNo = that.newOrg.phoneNumber.trim();
        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!myreg.test(phoneNo)) {
          IOT.tips('请输入正确的11位手机号码', 'error');
          return;
        }

        let url = '/users/phoneMap';

        IOT.getServerData(url, 'get', { phoneNo: phoneNo }, (ret) => {
          if (ret.code === 200) {
            that.timeBool = true;
            that.timeCode = 60;
            that.timer = setInterval(function() {
              if (that.timeCode == 0) {
                clearInterval(that.timer);
                that.timeBool = false;
                that.timeCode = 60;
              } else {
                that.timeCode--;
              }
            }, 1000);
            this.$message({
              message: '验证码发送到您的手机，请您注意查看',
              type: 'success',
            });
          } else {
            that.timeBool = false;
            that.timeCode = 60;
            IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
          }
        });
      },

      //找回密码使用
      phoneCode2() {
        let that = this;
        var phoneNo = that.updatePassword.mobile.trim();
        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!myreg.test(phoneNo)) {
          IOT.tips('请输入正确的11位手机号码', 'error');
          return;
        }

        let url = '/users/phoneMap2';

        IOT.getServerData(url, 'get', { phoneNo: phoneNo }, (ret) => {
          if (ret.code === 200) {
            this.$message({
              message: '验证码发送到您的手机，请您注意查看',
              type: 'success',
            });
            that.timeBool = true;
            that.timeCode = 60;
            that.timer = setInterval(function() {
              if (that.timeCode == 0) {
                clearInterval(that.timer);
                that.timeBool = false;
                that.timeCode = 60;
              } else {
                that.timeCode--;
              }
            }, 1000);
          } else {
            that.timeBool = false;
            that.timeCode = 60;
            IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
          }
        });
      },

      getMenuIndex(url, m1, m2, m3) {
        let index = 0;
        for (let i = 0; i < m1.length; i++) {
          const e = m1[i];
          if (e.url === url) {
            index = 0;
            break;
          }
        }
        for (let i = 0; i < m2.length; i++) {
          const e = m2[i];
          if (e.url === url) {
            index = 1;
            break;
          }
        }
        for (let i = 0; i < m3.length; i++) {
          const e = m3[i];
          if (e.url === url) {
            index = 2;
            break;
          }
        }
        return index;
      },
      commit() {
        var that = this;
        if (!this.form.account) {
          PF.publicMessage('请输入<i class="iconfont icon-zhanghao"></i>账号!', 'error');
          return false;
        } else if (!this.form.password) {
          PF.publicMessage('请输入<i class="iconfont icon-mima"></i>密码!', 'error');
          return false;
        } else if (this.sliderValue !== 100) {
          PF.publicMessage('请滑动验证!', 'error');
          return false;
        }
        PF.publicLoadingShow('登陆中,请稍后...');

        IOT.getServerData(
          '/login',
          'post',
          this.form,
          (ret) => {
            PF.publicLoadingHide();
            if (ret.code === 200) {
              PF.publicMessage('登陆成功!', 'success', 1000);
              PF.setLocal('account', that.form.account);
              //  PF.setLocal("account",that.form.account);
              PF.setLocal('password', that.form.password);
              PF.setLocal('token', ret.data.token);
              PF.setLocal('userName', ret.data.userName);
              PF.setLocal('organizationId', ret.data.organizationId);
              PF.setLocal('organizationName', ret.data.organizationName);
              PF.setLocal('menus1', ret.data.menus1);
              PF.setLocal('menus2', ret.data.menus2);
              PF.setLocal('menus3', ret.data.menus3);
              // localStorage.setItem(
              //     "menus2",
              //     JSON.stringify(ret.data.menus2)
              // );
              // localStorage.setItem(
              //     "menus3",
              //     JSON.stringify(ret.data.menus3)
              // );

              // 根据返回的角色路径匹配到菜单所属的4个选项

              PF.setLocal('menu_index', 0);
              if (this.prevRoute != null) {
                this.$router.push(this.prevRoute);
              } else {
                this.$router.push({ path: '/homepage' });
              }
              //  this.$router.push({ path: '/homepage' });
            } else {
              PF.publicMessage(ret.message || '服务器请求错误!', 'error');
            }
          },
          true
        );

        /*this.$http.post('/login', this.form).then(res=>{
                    PF.publicLoadingHide();
                    if(res.data.code === 200){
                        PF.publicMessage('登陆成功!','success');
                        localStorage.setItem("token",res.data.data.token);
                        localStorage.setItem("userName",res.data.data.userName);
                        localStorage.setItem("menus1",JSON.stringify(res.data.data.menus1));
                        localStorage.setItem("menus2",JSON.stringify(res.data.data.menus2));
                        localStorage.setItem("menus3",JSON.stringify(res.data.data.menus3));
                        this.$router.push({name: 'home'})
                    }else{
                        PF.publicMessage(res.data.message || '服务器请求错误!','error');
                    }
                }).catch(err=>{
                    console.error(err || '未知的错误!','error');
                });*/
      },

      commitDw(token) {
        var that = this;
        PF.publicLoadingShow('登陆中,请稍后...');
        IOT.getServerData(
          '/login/dwUserLogin',
          'get',
          {
            token: token,
          },
          (ret) => {
            PF.publicLoadingHide();
            if (ret.code === 200) {
              PF.publicMessage('登陆成功!', 'success', 1000);
              PF.setLocal('account', that.form.account);
              //  PF.setLocal("account",that.form.account);
              PF.setLocal('password', that.form.password);
              PF.setLocal('token', ret.data.token);
              PF.setLocal('userName', ret.data.userName);
              PF.setLocal('organizationId', ret.data.organizationId);
              PF.setLocal('organizationName', ret.data.organizationName);
              PF.setLocal('menus1', ret.data.menus1);
              PF.setLocal('menus2', ret.data.menus2);
              PF.setLocal('menus3', ret.data.menus3);

              PF.setLocal('menu_index', 0);
              if (this.prevRoute != null) {
                this.$router.push(this.prevRoute);
              } else {
                this.$router.push({ path: '/homepage' });
              }
            } else {
              PF.publicMessage(ret.message || '服务器请求错误!', 'error');
            }
          },
          true
        );
      },
    },
    beforeCreate() {},
    created() {
      var account = PF.getLocal('account');
      var password = PF.getLocal('password');
      if (account != '' && account != null && account != undefined && password != '' && password != null && password != undefined) {
        this.form.account = account;
        this.form.password = password;
      }
      this.getAuthParams();
    },
    mounted() {
      //   const access_token =getQueryVariable('access_token');
      //   replaceParamVal('access_token',"");
      //   localStorage.setItem('token',access_token);
      //   if(access_token!=null && access_token.trim()!='' ){
      //     this.commitDw(access_token);
      //   }
    },
    destroyed() {
      clearInterval(this.timer);
    },
  };
</script>

<style scoped lang="less">
  .login {
    color: #08274c;
    padding-bottom: 30px;
    height: 1200px;
    overflow: hidden;
    background-image: url(../assets/images/20220403010653.jpg);

    .loginModal {
      width: 50%;
      height: 335px;
      margin: 20px 30px 0px 0px;
      background: #fff;
      ul {
        width: 280px;
        margin: 50px auto;
        li {
          text-align: center;
          margin: 20px 0;
        }
        li:first-child {
          span {
            display: inline-block;
            width: 50%;
            line-height: 30px;
          }
        }
      }
    }
  }
</style>
